import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RegistrationForm from "../components/registration-form"

const RegistrationPage = ({location}) => (
  <Layout location={location} noContactForm>
    <SEO title="Registration" />
    <h2>GEMS Registration</h2>
    <RegistrationForm />
  </Layout>
)

export default RegistrationPage;

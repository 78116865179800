import React, { useState } from "react"

import {FormInput, FormSelect, FormTextarea, FormRadio, RegistrationGirl } from './form';

const RegistrationForm = () => {
  const [girlCount, setGirlCount] = useState(6);
  const [noConsent, setNoConsent] = useState('')

  const updateGirlCount = event => {
    setGirlCount(Number(event.target.value));
  };

  const handleNoMedicalConsent = event => {
    setNoConsent(event.target.id)
  }

  let childrenInfo = []; 
  for(let ndx = 0; ndx < girlCount; ndx++) {
    childrenInfo.push(
      <RegistrationGirl
        number={ndx+1}
      />
    )
  }

  return (
    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="gems-registration">
      <div className="row gtr-uniform">
        <div className="col-12">
          <h3>Parent Info</h3>
        </div>
        <FormInput 
          positioning="col-6 col-12-xsmall"
          label="Name"
          id="name"
          required
        />
        <FormInput 
          positioning="col-6 col-12-xsmall"
          label="Email"
          id="email"
          type="email"
          required
        />
        <FormInput 
          positioning="col-12"
          label="Address"
          id="address"
          required
        />
        <FormInput 
          positioning="col-6 col-12-small"
          label="City"
          id="city"
          required
        />
        <FormSelect
          positioning="col-3 col-6-small col-12-xsmall"
          label="State"
          id="state"
          required
          options={[
            {value: "OH", label: "OH"},
            {value: "IN", label: "IN"},
            {value: "MI", label: "MI"},
          ]}
        />
        <FormInput 
          positioning="col-3 col-6-small col-12-xsmall"
          label="Zip"
          id="zip"
          required
          pattern="[0-9]{5}" 
        />
        <FormInput 
          positioning="col-6 col-12-small"
          label="Home Church"
          id="church"
        />
        <FormInput 
          positioning="col-3 col-12-small"
          label="Phone Number"
          id="telephone"
          type="tel"
          required
        />

        <div className="col-12">
          <h3>Emergency Contact Info</h3>
        </div>
        <FormInput 
          positioning="col-6 col-12-small"
          label="Name"
          id="emergency-name"
          required
        />
        <FormInput 
          positioning="col-3 col-12-small"
          label="Relationship"
          id="emergency-rel"
          required
        />
        <FormInput 
          positioning="col-3 col-12-small"
          label="Phone Number"
          id="emergency-telephone"
          type="tel"
          required
        />

        <div className="col-12">
          <h3>Pick up</h3>
        </div>
        <FormTextarea
          positioning="col-6 col-12-small"
          label="Adults who can pick up girls"
          id="allowed-adults"
        />
        <FormTextarea
          positioning="col-6 col-12-small"
          label="Adults not allowed to pick up girls"
          id="restricted-adults"
        />

        <div className="col-12">
          <h3>{girlCount === 1 ? "Girl" : "Girls"}</h3>
        </div>
        <FormSelect
          positioning="col-3 col-12-small"
          label="Number attending"
          id="number-of-girls"
          required
          options={[
            {value: "6", label: "6"},
            {value: "5", label: "5"},
            {value: "4", label: "4"},
            {value: "3", label: "3"},
            {value: "2", label: "2"},
            {value: "1", label: "1"},
          ]}
          onChange={updateGirlCount}
        />
        {childrenInfo}

        <div className="col-12">
          <h3>Medical Consent</h3>
        </div>
        <FormRadio 
          positioning="col-12"
          text="Parents/Guardians: In the event reasonable attempts to contact me have been unsuccessful, I hereby give my consent for adults at BCC to topically wash an open wound, apply a bandage, or provide an ice pack for bumps and bruises. In the event of a serious emergency, I give BCC permission to call 911 with transport to Community Hospital of Williams County-Bryan"
          id="medical-consent"
          required
          options={[
            {id: "consent", label: "I give permission to act", onChange: handleNoMedicalConsent},
            {id: "no-consent", label: "I do NOT give permission to act", onChange: handleNoMedicalConsent},
          ]}
        />
        <FormTextarea
          positioning="col-12"
          label="In the event of illness or injury requiring medical attention, I wish adults at BCC to take the following action."
          id="no-consent-procedure"
          hidden={noConsent !== 'medical-consent-no-consent'}
        />
        <div className="col-12">
          <h3>Media Consent</h3>
        </div>
        <FormRadio 
          positioning="col-12"
          text="I hereby give my consent to photograph and/or video my child/children for the purpose of showing pictures and/or video to the congregation of Bridgewater Community Church and/or social media."
          id="media-consent"
          required
          options={[
            {id: "consent", label: "I give consent to media use of my children as stated above"},
            {id: "no-consent", label: "I do NOT give consent to media use of my children as stated above"},
          ]}
        />
        <div className="col-12">
          <h3>Transport Consent</h3>
        </div>
        <FormRadio 
          positioning="col-12"
          text="I hereby give permission for my child/children to be transported to and from events by volunteer drivers authorized by Bridgewater Community Church."
          id="transport-consent"
          required
          options={[
            {id: "consent", label: "I give permission for my children to be transported"},
            {id: "no-consent", label: "I do NOT give permission for my children to be transported"},
          ]}
        />

        <div className="col-12">
          <h3>Registration Fee</h3>
        </div>
        <FormRadio 
          positioning="col-12"
          text="I am capable of paying the $20 registration fee per child. If you are not able to pay the registration fee, scholarships are available. Please contact the church at (419) 485-8229 for more information."
          id="registration-fee"
          required
          options={[
            {id: "capable", label: "Yes"},
            {id: "not-capable", label: "No"},
          ]}
        />

        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="gems-registration" />

        <div className="col-12">
          <ul className="actions">
            <li><input type="submit" value="Send Registration" className="button primary large"/></li>
          </ul>
        </div>
      </div>
    </form>
  )
}

export default RegistrationForm;
